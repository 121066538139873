body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  
  h1, h2, h3, h4, h5, h6{
    font-family: 'League Spartan', sans-serif !important;
    font-display: swap;
    line-height: 1.2em;
  }
  
  h1{
    font-size: 3.5em;
  }
  
  h2{
    font-size: 3em;
  }
  h3{
    font-size: 2.5em;
  }
  h4{
    font-size: 2em;
  }
  
  h5{
    font-size: 1.5em;
  }
  
  h6{
    font-size: 1em;
  }
  
  p{
   font-family: 'helvetica', sans-serif !important;
   font-weight: 100;
   font-size: 1.2em;
   line-height: 1.8em;
   font-display: swap;
   
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a{
    font-family: 'helvetica', sans-serif;
  }

 .password-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  

  background-color: black;
  color: white;
 }

 .password-container form{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 200px;
 }

 .password-container label{
  font-size: 30px;
  font-weight: bold;
 }

 .password-container input{
  width: 200px;
  height: 20px;
 }

  .slick-list{
    display: grid !important;
    place-items: center;
  }

  .slick-slide > div {
    display: grid;
    place-items: center;
}
  
  .counting-number{
    font-family: 'League Spartan', sans-serif;
  }

  .secondary-dark-bg{
    background-color: var(--secondary-dark-bg);
  }